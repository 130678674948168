html{
    font-size: 10px;
}

body{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    padding: 0;
    font-size: 1.6rem;
}

a{
    text-decoration: none;
}

/*
* HEADER
*/

header{
   height: 10rem;
   position: relative;
   display: flex;
   align-items: center;
   column-gap: 3rem;
}

.header-hamburger-menu-wrapper{
    display: none;
}

.header-hamburger-menu{
    width: 40px;
    height: 40px;
    background:center no-repeat;
    background-size: contain;
    background-image: url('./../images/menu-icon-menu.svg');
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
}

.header-logo-wrapper{
    padding-left: 2rem;
}

.header-menu-wrapper{
    flex-grow: 2;
    z-index: 3;
}

.main-menu nav, 
.header-user-container nav{
    display: flex;
    padding: 0 2rem;
    gap: 0 2rem;
}

nav a{
    color: #707070;
    font-size: 2rem;
}

nav a.active{
    color: #00A79E;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* IE10+ CSS */
    .main-menu nav a, 
    .header-user-container nav a{
        padding: 0 1rem;
    }
}

/*
 * MAIN SLIDER
 */

.main-slider-wrapper{
    position: absolute;
    top: 10rem;
    left: 0;
    right: 0;
    bottom: 21.5rem;
}

.main-slider-container{
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #707070;
    border-left: none;
    border-right: none;
}

.main-slider{
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
}

.main-slider-item{
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.main-slider-item .main-slider-item-image{
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.main-slider-item.active{
    left: 0;
}

.main-slider-item.next{
    left: 100%;
}

.main-slider-item.prev{
    left: -100%;
}

.main-slider-item .main-slider-item-text{
    position: absolute;
}

/*
 * MAIN WIDGETS
 */
.main-widgets-wrapper{
    position: absolute;
    bottom: 1.5rem;
    left: 0;
    right: 0;
    height: 19rem;
}

.main-widgets-container{
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: space-around;
    justify-content: space-evenly;
}

.main-widgets-delimiter{
    width: 1px;
    height: 60%;
    background-color: #707070;
}

.main-widget{
    display: flex;
    align-items: center;
}

.widget-icon{
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.widget-icon--calendar{
    width: 7.5rem;
    height: 7.5rem;
    background-image: url('./../images/widget-calendar-icon.svg');
}

.widget-icon--price{
    width: 3.6rem;
    height: 8rem;
    background-image: url('./../images/widget-price-icon.svg');
}

.widget-icon--location{
    width: 8.3rem;
    height: 8.8rem;
    background-image: url('./../images/widget-location.icon.svg');
}

.widget-data{
    margin-left: 2.5rem;
}

.widget-month-name{
    font-weight: bold;
    font-size: 2rem;
    color: #9F1E63;
    line-height: 2.1rem;
}

.widget-month-free-dates{
    font-size: 2rem;
    line-height: 2.1rem;
    color: #000;
}

.widget-price-number{
    font-weight: bold;
    font-size: 4.5rem;
    color: #BF1E2D;
}

.widget-price-text{
    font-size: 1.8rem;
    color: #000;
}

.widget-position{
    font-size: 2.2rem;
    color: #000;
}

/*
 * FOOTER
 */
footer{
    position: absolute;
    bottom: 0;
    height: 2.5rem;
    left: 0;
    right: 0;
}

.footer-copyright{
    position: absolute;
    font-size: 1.2rem;
    right: 1.5rem;
    bottom: 1rem;
    color: #707070;
}

/*
 * PAGES GLOBAL
 */
.fader{
    position: fixed;
    top: 10rem;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
}

.page-wrapper{
    position: absolute;
    top: 7.5rem;
    left: 7.5rem;
    right: 7.5rem;
    bottom: 5rem;
    background-color: #FFF;
    border-radius: 12px;
    z-index: 4;
    box-shadow: 0 0 10px rgba(0,0,0,0.4);
}

/*
 *
 * low res screen (less than 1200px)
 *
 */
@media screen and (max-width:1200px) {
    html{
        font-size: 8px;
    }

    body{
        font-size: 1.8rem;
    }
    
}

/*
 *
 * low res screen (700 - 950px)
 *
 */

@media screen and (min-width:760px) and (max-width:950px) {
    header{
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
    }

    .header-menu-wrapper, .header-user-wrapper{
        padding: 5px 0;
    }

    .header-logo-wrapper{
        align-self: flex-start;
    }

    .header-menu-wrapper{
        order: 3;
        flex-grow: 0;
    }

    .page-wrapper{
        top: 8.5rem;
        left: 5rem;
        right: 5rem;
        bottom: 5rem;
    }
}

@media screen and (max-width: 759px){
    .header-menu-wrapper{
        width: 100%;
    }

    .main-menu nav, .header-user-container nav{
        flex-wrap: wrap;
        flex-basis: 100%;
    }

    .page-wrapper{
        top: 8.5rem;
        left: 5rem;
        right: 5rem;
        bottom: 5rem;
    }
}

/*
 *
 * MOBIle
 *
 */
 @media screen and (max-width: 700px){
    header{
        flex-direction: column;
        align-items: baseline;
    }
    
    .header-hamburger-menu-wrapper{
        display: block;
    }

    .header-logo-wrapper{
        width: 160px;
        padding-left: 0;
        order: -1;
        align-self: center;
    }

    .header-logo-container a{
        display: flex;
        height: 60px;
        align-content: center;
        justify-content: center;
    }

    .header-logo-wrapper img{
        width: 100%;
        align-self: center;
    }

    .header-menu-wrapper{
        flex-grow: 0;
        width: 100%;
        z-index: 5;
    }

    .header-user-wrapper{
        z-index: 5;
        width: 100%;
    }

    .main-menu nav, .header-user-container nav{
        flex-direction: column;
        border-top: 1px solid #707070;
        background: rgba(255, 255, 255,0.9);
    }

    .main-menu nav a, .header-user-container nav a{
        padding: 3px 0;
    }

    .fader{
        display: none;
    }

    .page-wrapper{
        top: 65px;
        left: 12px;
        right: 12px;
        bottom: 12px;
        box-shadow: 0 0 10px rgba(0,0,0,0.4);
    }
 }

 @media screen and (max-width: 600px){
     .main-widget{
         flex-direction: column;
         overflow: hidden;
         padding: 0 5px;
     }

     .main-widgets-delimiter{
         height: 80%;
     }

     .widget-data{
         margin-left: 0;
         width: 100%;
     }
     
     .widget-price{
        text-align: center;
    }

     .widget-price-number{
         font-size: 3rem;
     }

     .widget-price-text{
         font-size: 1.5rem;
     }

     .widget-position{
         font-size: 1.8rem;
     }

     .widget-month-free-dates{
         overflow: hidden;
         text-overflow: ellipsis;
         white-space: nowrap;
     }

     .widget-month{
         white-space: nowrap;
         text-overflow: ellipsis;
         overflow: hidden;
     }

 }